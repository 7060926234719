var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('t-modal',{ref:"popup",attrs:{"name":_vm.id,"variant":"subscription","hideCloseButton":true,"disableBodyScroll":true}},[_c('section',{staticClass:"px-8 pt-10"},[_c('div',{staticClass:"font-medium text-28px text-oCharcoal",domProps:{"innerHTML":_vm._s(
          _vm.$t(
            'components.reportManagement.modals.verificationPopupB.headline.title',
            {
              status:
                _vm.details.status === 'Verified'
                  ? _vm.$t(
                      'components.reportManagement.modals.verificationPopupB.headline.revertUppercase'
                    )
                  : _vm.$t(
                      'components.reportManagement.modals.verificationPopupB.headline.verifyUppercase'
                    ),
            }
          )
        )}}),_c('div',{staticClass:"mt-2 font-medium text-19px text-oCharcoal",domProps:{"innerHTML":_vm._s(
          _vm.$t(
            'components.reportManagement.modals.verificationPopupB.headline.subtext',
            {
              status:
                _vm.details.status === 'Verified'
                  ? _vm.$t(
                      'components.reportManagement.modals.verificationPopupB.headline.revertLowercase'
                    )
                  : _vm.$t(
                      'components.reportManagement.modals.verificationPopupB.headline.verifyLowercase'
                    ),
            }
          )
        )}}),_c('section',{staticClass:"mt-10"},[_c('t-checkbox',{attrs:{"wrapped":"","label":_vm.$t(
              'components.reportManagement.modals.verificationPopupB.title.addRemarks'
            ),"name":"adminRemarksUi"},model:{value:(_vm.adminRemarksUi),callback:function ($$v) {_vm.adminRemarksUi=$$v},expression:"adminRemarksUi"}}),_c('div',{staticClass:"-mt-2 text-14px",staticStyle:{"margin-left":"28px"}},[_c('span',{staticClass:"text-gray-500"},[_vm._v(" "+_vm._s(_vm.$t( 'components.reportManagement.modals.verificationPopupB.subtext.addRemarks' ))+" ")])])],1),_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.adminRemarksUi),expression:"adminRemarksUi"}],staticClass:"mt-6"},[_c('t-textarea',{model:{value:(_vm.adminRemarks),callback:function ($$v) {_vm.adminRemarks=$$v},expression:"adminRemarks"}})],1),_c('div',{staticClass:"flex items-center justify-end px-4 py-4 mt-12 mb-4"},[_c('anchor-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$refs.popup.hide()}}},[_vm._v(" "+_vm._s(_vm.$t('components.stepNavigation.cancel'))+" ")]),_c('anchor-button',{staticClass:"ml-4",attrs:{"variant":"primary"},on:{"click":_vm.executeAction}},[_vm._v(" "+_vm._s(_vm.$t('components.stepNavigation.continue'))+" ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }