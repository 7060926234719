<template>
  <div>
    <t-modal
      :name="id"
      variant="subscription"
      :hideCloseButton="true"
      :disableBodyScroll="true"
      ref="popup"
    >
      <section class="px-8 pt-10">
        <div
          class="font-medium text-28px text-oCharcoal"
          v-html="
            $t(
              'components.reportManagement.modals.verificationPopupB.headline.title',
              {
                status:
                  details.status === 'Verified'
                    ? $t(
                        'components.reportManagement.modals.verificationPopupB.headline.revertUppercase'
                      )
                    : $t(
                        'components.reportManagement.modals.verificationPopupB.headline.verifyUppercase'
                      ),
              }
            )
          "
        ></div>

        <div
          class="mt-2 font-medium text-19px text-oCharcoal"
          v-html="
            $t(
              'components.reportManagement.modals.verificationPopupB.headline.subtext',
              {
                status:
                  details.status === 'Verified'
                    ? $t(
                        'components.reportManagement.modals.verificationPopupB.headline.revertLowercase'
                      )
                    : $t(
                        'components.reportManagement.modals.verificationPopupB.headline.verifyLowercase'
                      ),
              }
            )
          "
        ></div>

        <section class="mt-10">
          <t-checkbox
            wrapped
            v-model="adminRemarksUi"
            :label="
              $t(
                'components.reportManagement.modals.verificationPopupB.title.addRemarks'
              )
            "
            :name="`adminRemarksUi`"
          />
          <div class="-mt-2 text-14px" style="margin-left: 28px;">
            <span class="text-gray-500">
              {{
                $t(
                  'components.reportManagement.modals.verificationPopupB.subtext.addRemarks'
                )
              }}
            </span>
          </div>
        </section>

        <section v-show="adminRemarksUi" class="mt-6">
          <t-textarea v-model="adminRemarks" />
        </section>

        <div class="flex items-center justify-end px-4 py-4 mt-12 mb-4">
          <anchor-button variant="secondary" @click="$refs.popup.hide()">
            {{ $t('components.stepNavigation.cancel') }}
          </anchor-button>

          <anchor-button variant="primary" class="ml-4" @click="executeAction">
            {{ $t('components.stepNavigation.continue') }}
          </anchor-button>
        </div>
      </section>
    </t-modal>
  </div>
</template>

<script>
import { EventBus } from '@/utils/EventBus'
import { VerificationPopupMixin } from './VerificationPopupMixin'

export default {
  name: 'ReportVerificationPopupB',
  mixins: [VerificationPopupMixin],
  components: {
    AnchorButton: () => import('@/components/form/AnchorButton.vue'),
    // TextInput: () => import('@/components/form/TextInput.vue'),
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    details: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mid: 'reportVerificationPopupB',
      isLoading: false,
      adminRemarks: null,
      adminRemarksUi: false,
    }
  },
  mounted() {
    EventBus.$on('reportVerificationPopup', () => {})
  },
}
</script>

<style lang="scss" scoped>
@import '$report-user-card';
.popup-container {
  display: grid;
  grid-template-rows: auto 1fr auto;
}
</style>
